<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("participations.detail.title") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon text>
          <v-icon @click="dialog.display = false">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <div v-if="participation.id">
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("participations.fields.created.description")
                }}</span>
              </div>
              <div class="ml-auto">
                {{
                  $moment(participation.created).format("YYYY-MM-DD HH:mm:ss")
                }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("participations.fields.clientId.description")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.client.identifier }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("participations.fields.code.description")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.code }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("participations.fields.code.description")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.code }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("participations.fields.usedCode.description")
                }}</span>
              </div>
              <div class="ml-auto">
                <v-chip class="table-chip" color="third">
                  <span class="black--text fonw-weight-bold">
                    <span v-if="participation.usedCode">{{
                      $t("btn.yes")
                    }}</span>
                    <span v-else>{{ $t("btn.no") }}</span>
                  </span>
                </v-chip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("participations.fields.checkedCode.description")
                }}</span>
              </div>
              <div class="ml-auto">
                <v-chip class="table-chip" color="third">
                  <span class="black--text fonw-weight-bold">
                    <span v-if="participation.checkedCode">{{
                      $t("btn.yes")
                    }}</span>
                    <span v-else>{{ $t("btn.no") }}</span>
                  </span>
                </v-chip>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.name.description2")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.reseller.name }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.identifier.description2")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.reseller.identifier }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("resellers.fields.type.description2")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ $t('resellers.fields.type.' + participation.reseller.type) }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("promotions.fields.name.description2")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.promotion.name }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="subtitle-1 mb-2">
            <div class="d-flex align-center mb-3">
              <div>
                <span class="font-weight-bold third--text">{{
                  $t("promotions.fields.identifier.description2")
                }}</span>
              </div>
              <div class="ml-auto">
                {{ participation.promotion.identifier }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    participation: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
  },
  data: () => ({}),
};
</script>