<template>
  <div>
    <div class="mb-4">
      <div class="text-h6 font-weight-medium third--text">
        {{ $t("participations.title") }}
      </div>
    </div>
    <v-card class="rounded-md mb-4" dark color="fifth">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("btn.refresh") }}
        </v-btn>
        <v-btn
          depressed
          class="mr-2"
          @click="getFilters()"
          :loading="loading.filter"
        >
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $t("btn.filter") }}
        </v-btn>
        <v-btn
          depressed
          @click="exportParticipations()"
          :loading="exportLoading"
        >
          <v-icon left>mdi-file-excel-outline</v-icon>
          {{ $t("btn.export") }}
        </v-btn>
      </div>
    </v-card>
    <v-card dark color="fifth">
      <v-card-title>
        <div class="text-subtitle-1">
          <span class="third--text">{{ $t("participations.list.title") }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn outlined color="third" v-if="meta.total">{{
          $tc("counter.participation", meta.total, {
            count: $utils.pad(meta.total, 2),
          })
        }}</v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :server-items-length="meta.total"
        sort-by="id"
        sort-desc
        dark
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>{{
                      $moment(item.created).format("YYYY-MM-DD HH:mm:ss")
                    }}</span>
                  </td>
                  <td>{{ item.client.identifier }}</td>
                  <td>{{ item.code }}</td>
                  <td>
                    <v-chip class="table-chip" color="third">
                      <span class="black--text fonw-weight-bold">
                        <span v-if="item.usedCode">{{ $t("btn.yes") }}</span>
                        <span v-else>{{ $t("btn.no") }}</span>
                      </span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="table-chip" color="third">
                      <span class="black--text fonw-weight-bold">
                        <span v-if="item.checkedCode">{{ $t("btn.yes") }}</span>
                        <span v-else>{{ $t("btn.no") }}</span>
                      </span>
                    </v-chip>
                  </td>
                  <td>{{ item.reseller.name }}</td>
                  <td>{{ item.promotion.name }}</td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="getDetails(item)"
                          v-show="hover || $vuetify.breakpoint.mdAndDown"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        >
                          <v-icon color="third">mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.details") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
      <participation-detail
        :dialog="dialog.detail"
        :participation="selectedParticipation"
      ></participation-detail>
      <participation-filter
        :dialog="dialog.filter"
        @applyFilter="filterItems"
      ></participation-filter>
      <participation-export
        :dialog="dialog.export"
        :field="exportField"
        @exportConfirm="exportConfirmed"
      ></participation-export>
    </v-card>
  </div>
</template>

<script>
import ListMixin from "./../../mixins/commons/list";
import ExportMixin from "./../../mixins/commons/export";
import ParticipationDetail from "./detail.vue";
import ParticipationFilter from "./filter";
import ParticipationExport from "./../commons/export.vue";

export default {
  mixins: [ListMixin, ExportMixin],
  data: () => ({
    path: "api/v1/participants",
    selectedParticipation: {},
    dialog: {
      detail: {
        display: false,
      },
      filter: {
        display: false,
      },
      export: {
        display: false,
        title: null,
      },
    },
    exportField: {
      title: null,
      placeholder: null,
    },
    exportUrl: "api/v1/participants/export",
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("participations.fields.created.title"),
          align: "start",
          sortable: true,
          value: "created",
          width: 160,
        },
        {
          text: this.$t("participations.fields.clientId.title"),
          align: "start",
          sortable: false,
          value: "client.identifier",
          width: 100,
        },
        {
          text: this.$t("participations.fields.code.title"),
          align: "start",
          sortable: false,
          value: "code",
          width: 100,
        },
        {
          text: this.$t("participations.fields.usedCode.title"),
          align: "start",
          sortable: true,
          value: "usedCode",
          width: 120,
        },
        {
          text: this.$t("participations.fields.checkedCode.title"),
          align: "start",
          sortable: true,
          value: "checkedCode",
          width: 120,
        },
        {
          text: this.$t("participations.fields.reseller.title"),
          align: "start",
          sortable: false,
          value: "resellerPromotion.reseller.name",
          width: 160,
        },
        {
          text: this.$t("participations.fields.promotion.title"),
          align: "start",
          sortable: false,
          value: "resellerPromotion.promotion.name",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: "60",
        },
      ];
    },
    getFilters() {
      this.dialog.filter.display = true;
    },
    getDetails(participation) {
      this.selectedParticipation = participation;
      this.dialog.detail.display = true;
    },
    exportParticipations() {
      this.exportField = {
        title: this.$t("participations.fields.created.description2"),
        placeholder: this.$t("participations.fields.created.title"),
      };
      this.dialog.export = {
        display: true,
        title: this.$t("participations.filter.title"),
      };
    },
  },
  components: {
    ParticipationDetail,
    ParticipationFilter,
    ParticipationExport,
  },
};
</script>