<template>
  <v-dialog
    v-model="dialog.display"
    max-width="500"
    :overlay-opacity="'0.5'"
    :overlay-color="$vuetify.theme.themes.light.third"
  >
    <v-card dark>
      <v-card-title>
        <div class="text--h6">{{ $t("participations.filter.title") }}</div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-form autocomplete="off">
          <v-dialog
            ref="dialogDaterange"
            v-model="dialogDaterange"
            :return-value.sync="daterange"
            persistent
            width="290px"
            v-if="!hideDaterange"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :label="$t('participations.fields.created.description2')"
                :placeholder="$t('participations.fields.created.title')"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              dark
              range
              v-model="daterange"
              color="primary"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="third" @click="dialogDaterange = false">
                {{ $t("btn.cancel") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="$refs.dialogDaterange.save(daterange)"
              >
                {{ $t("btn.ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-text-field
            :label="$t('participations.fields.clientId.description')"
            v-model="filters.c_identifier.value"
            clearable
            v-if="!hideParticipantIdentifier"
          ></v-text-field>
          <v-select
            :label="$t('participations.fields.checkedCode.title')"
            :items="items.checkedCode"
            :item-text="(item) => $t(item.text)"
            clearable
            v-model="filters.checkedCode.value"
          ></v-select>
          <v-select
            :label="$t('participations.fields.usedCode.title')"
            :items="items.usedCode"
            :item-text="(item) => $t(item.text)"
            clearable
            v-model="filters.usedCode.value"
          ></v-select>
          <v-autocomplete
            :label="$t('resellers.fields.name.description2')"
            :loading="loading.reseller"
            :items="items.resellers"
            item-text="name"
            item-value="identifier"
            :search-input.sync="search.reseller"
            hide-no-data
            v-model="filters.r_identifier.value"
            clearable
            persistent-hint
            :hint="$t('participations.fields.reseller.helper')"
          ></v-autocomplete>
          <v-autocomplete
            :label="$t('promotions.fields.name.description2')"
            :loading="loading.promotion"
            :items="items.promotions"
            item-text="name"
            item-value="identifier"
            :search-input.sync="search.promotion"
            hide-no-data
            v-model="filters.p_identifier.value"
            clearable
            persistent-hint
            :hint="$t('participations.fields.promotion.helper')"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="resetFilter()" color="third" text>{{
          $t("btn.reset")
        }}</v-btn>
        <v-btn @click="applyFilter()" color="secondary" text>{{
          $t("btn.filter")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";

const defaultFilters = {
  c_identifier: {
    value: null,
  },
  r_identifier: {
    value: null,
  },
  p_identifier: {
    value: null,
  },
  checkedCode: {
    value: null,
  },
  usedCode: {
    value: null,
  },
  created_lte: {
    value: null,
  },
  created_gte: {
    value: null,
  },
};

export default {
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    hideDaterange: {
      type: Boolean,
      default: false,
    },
    hideParticipantIdentifier: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filters: Vue._.cloneDeep(defaultFilters),
    loading: {
      reseller: false,
      promotion: false,
    },
    items: {
      resellers: [],
      checkedCode: [
        { value: true, text: "btn.yes" },
        { value: false, text: "btn.no" },
      ],
      usedCode: [
        { value: true, text: "btn.yes" },
        { value: false, text: "btn.no" },
      ],
    },
    search: {
      reseller: null,
      promotion: null,
    },
    dialogDaterange: false,
    daterange: [],
  }),
  methods: {
    ...mapActions({ request: "request" }),
    applyFilter() {
      this.$emit("applyFilter", this.filters);
      this.dialog.display = false;
    },
    resetFilter() {
      this.filters = Vue._.cloneDeep(defaultFilters);
      this.$emit("applyFilter", {});
      this.dialog.display = false;
    },
  },
  watch: {
    async "search.reseller"(val) {
      if (!val) return;
      if (this.loading.reseller) return;

      this.loading.reseller = false;
      try {
        const response = await this.request({
          url: "api/v1/resellers",
          params: {
            name: val,
          },
        });
        this.items.resellers = response.data.items;
      } catch (error) {
        // empty
      }
    },
    async "search.promotion"(val) {
      if (!val) return;
      if (this.loading.promotion) return;

      this.loading.promotion = false;
      try {
        const response = await this.request({
          url: "api/v1/promotions",
          params: {
            name: val,
          },
        });
        this.items.promotions = response.data.items;
      } catch (error) {
        // empty
      }
    },
    daterange: {
      handler(val) {
        this.filters.created_lte.value = null;
        this.filters.created_gte.value = null;
        if (val.length === 2) {
          const dates = this.$utils.daterangeAlign(this.daterange);
          this.filters.created_lte.value = dates[1] + " 23:59:59";
          this.filters.created_gte.value = dates[0] + " 00:00:00";
        }
      },
      deep: true,
    },
  },
  computed: {
    dateRangeText: {
      get: function () {
        if (this.daterange.length !== 2) return;
        const dates = this.$utils.daterangeAlign(this.daterange);
        return dates.join(" - ");
      },
      set: function (val) {
        if (!val) {
          this.daterange = [];
        } else {
          const dates = val.split(" - ");
          if (
            this.$moment(dates[0], "YYYY-MM-DD", true).isValid() &&
            this.$moment(dates[1], "YYYY-MM-DD", true).isValid()
          ) {
            this.daterange = [dates[0], dates[1]];
          }
        }
      },
    },
  },
};
</script>