<template>
  <div>
    <participation></participation>
  </div>
</template>

<script>
import Participation from "./../../components/participations/list";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("participations.title")
    };
  },
  components: {
    Participation
  }
};
</script>